import React from "react"

import SEO from "../components/seo"
import { Link } from "gatsby";

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <div className="page-not-found"> 
      <div className="container">
        <div className="page-not-found-wrap text-center">
          <div className="page-not-found-content">
            <h1 className="title">404</h1>
            <h5 className="sub-title">Oops, Sorry we can't find that page!</h5>
            <p>Either something went wrong or the page doesn't exit anymore.</p>
            <div className="home-link-btn">
            <Link to="/" className="dark-logo">Back to Home</Link>
              
            </div>
          </div>
        </div>
      </div>
      </div>
  </>
)

export default NotFoundPage
